export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_PROFILE_DETAIL_REQUEST = 'USER_PROFILE_DETAIL_REQUEST'
export const USER_PROFILE_DETAIL_SUCCESS = 'USER_PROFILE_DETAIL_SUCCESS'
export const USER_PROFILE_DETAIL_FAIL = 'USER_PROFILE_DETAIL_FAIL'
export const USER_PROFILE_DETAIL_RESET = 'USER_PROFILE_DETAIL_RESET'


export const USER_PROFILE_UPDATE_REQUEST = 'USER_PROFILE_UPDATE_REQUEST'
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS'
export const USER_PROFILE_UPDATE_FAIL = 'USER_PROFILE_UPDATE_FAIL'
export const USER_PROFILE_UPDATE_RESET = 'USER_PROFILE_UPDATE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_CODE_REQUEST = 'USER_CODE_REQUEST'
export const USER_CODE_SUCCESS = 'USER_CODE_SUCCESS'
export const USER_CODE_FAIL = 'USER_CODE_FAIL'

export const USER_GET_REQUEST = 'USER_GET_REQUEST'
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS'
export const USER_GET_FAIL = 'USER_GET_FAIL'
export const USER_GET_RESET = 'USER_GET_RESET'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'