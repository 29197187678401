import NestedCategories from "../../components/NestedCategories";
import {CategoryType} from "../../components/NestedCategories/types";
import React from "react";

const defaultCategories: CategoryType[] =
    [
            {id: 1, title: "Organizmalar", parentId: 0},
            {id: 2, title: "Asellüler", parentId: 1},
            {id: 3, title: "Sellüler", parentId: 1},
            {id: 4, title: "Prionlar", parentId: 2},
            {id: 5, title: "Virüsler", parentId: 2},
            {id: 6, title: "Viroidler", parentId: 2},
            {id: 7, title: "Prokaryotlar", parentId: 3},
            {id: 8, title: "Ökaryotlar", parentId: 3},
            {id: 9, title: "Bakteriler", parentId: 7},
            {id: 10, title: "Arkeler", parentId: 7},
    ]


const NestedCategoriesScreen = () => {
    return (
        <NestedCategories defaultCategories={defaultCategories}/>
    )
}

export default NestedCategoriesScreen