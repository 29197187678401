import {createStyles, ThemeIcon, Text, Box, Stack} from '@mantine/core';
import {IconPhone, IconMapPin, IconAt} from '@tabler/icons-react';
import React from "react";

type ContactIconVariant = 'white' | 'gradient';

interface ContactIconStyles {
    variant: ContactIconVariant;
}

const useStyles = createStyles((theme, {variant}: ContactIconStyles) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        color: theme.white,
    },

    icon: {
        marginRight: theme.spacing.md,
        backgroundImage:
            variant === 'gradient'
                ? `linear-gradient(135deg, ${theme.colors[theme.primaryColor][4]} 0%, ${
                    theme.colors[theme.primaryColor][6]
                } 100%)`
                : 'none',
        backgroundColor: 'transparent',
    },

    title: {
        color: variant === 'gradient' ? theme.colors.gray[6] : theme.colors[theme.primaryColor][0],
    },

    description: {
        color: variant === 'gradient' ? theme.black : theme.white,
    },
}));

interface ContactIconProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
    icon: any;
    title: React.ReactNode;
    description: React.ReactNode;
    variant?: ContactIconVariant;
}

function ContactIcon({
                         icon: Icon,
                         title,
                         description,
                         variant = 'gradient',
                         className,
                         ...others
                     }: ContactIconProps) {
    const {classes, cx} = useStyles({variant});
    return (
        <div className={cx(classes.wrapper, className)} {...others}>
            {variant === 'gradient' ? (
                <ThemeIcon size={40} radius="md" className={classes.icon}>
                    <Icon size={24}/>
                </ThemeIcon>
            ) : (
                <Box mr="md">
                    <Icon size={24}/>
                </Box>
            )}

            <div>
                <Text size="xs" className={classes.title}>
                    {title}
                </Text>
                <Text className={classes.description}>{description}</Text>
            </div>
        </div>
    );
}

interface ContactIconsListProps {
    data?: ContactIconProps[];
    variant?: ContactIconVariant;
}

const MOCKDATA: ContactIconProps[] = [
    {title: 'Email', description: 'sakaryaciltip@gmail.com', icon: IconAt},
    {title: 'Telefon', description: '+90 (264) 888 40 00', icon: IconPhone},
    {
        title: 'Adres',
        description: 'Şirinevler, Adnan Menderes Cad.Sağlık Sok No:195, 54100 Adapazarı/Sakarya',
        icon: IconMapPin
    },
];

export function ContactIconsList({data = MOCKDATA, variant}: ContactIconsListProps) {
    return (
        <Stack>
            {data.map((item, index) => <ContactIcon key={index} variant={variant} {...item} />)}
        </Stack>
    );
}

