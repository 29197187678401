export const MD_100 = "#A7DEFF";
export const MD_200 = "#74CAFF";
export const MD_300 = "#41B7FF";
export const MD_400 = "#0EA4FF";
export const MD_500 = "#0087DA";
export const MD_600 = "#0068A7";
export const MD_700 = "#004874";
export const MD_800 = "#00385a";
export const MD_900 = "#001927";

export const YELLOW_100 = "#F5D5AF";
export const YELLOW_200 = "#F3C998";
export const YELLOW_300 = "#F0BD81";
export const YELLOW_400 = "#EDB26A";
export const YELLOW_500 = "#EAA654";
export const YELLOW_600 = "#0068A7";
export const YELLOW_700 = "#E58E26";
export const YELLOW_800 = "#C17417";
export const YELLOW_900 = "#935812";

export const RED_100 = "#F5DADE";
export const RED_200 = "#EAB2BA";
export const RED_300 = "#DF8A97";
export const RED_400 = "#D46273";
export const RED_500 = "#C93A4F";
export const RED_600 = "#A32D3E";
export const RED_700 = "#7B222F";
export const RED_800 = "#5B1923";
export const RED_900 = "#3B1017";

export const PURPLE_100 = "#EDDAF3";
export const PURPLE_200 = "#DBB4E6";
export const PURPLE_300 = "#C88ED9";
export const PURPLE_400 = "#B668CC";
export const PURPLE_500 = "#A442BF";
export const PURPLE_600 = "#84349A";
export const PURPLE_700 = "#632774";
export const PURPLE_800 = "#491D55";
export const PURPLE_900 = "#2F1237";

export const GRAY_100 = "#F5F5F5";
export const GRAY_200 = "#E5E5E5";
export const GRAY_300 = "#D4D4D4";
export const GRAY_400 = "#A3A3A3";
export const GRAY_500 = "#737373";
export const GRAY_600 = "#525252";
export const GRAY_700 = "#404040";
export const GRAY_800 = "#262626";
export const GRAY_900 = "#171717";