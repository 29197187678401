import React from "react";
import styled from "@emotion/styled";
import {Link} from "react-router-dom";

import {
    YELLOW_500,
} from "../colors";

import {
    InstagramIcon,
    FacebookIcon,
    TwitterIcon
} from "../icons";
import {MantineTheme, useMantineTheme} from "@mantine/core";
import {IconBrandYoutube} from "@tabler/icons-react";

const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 50px 20px;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 400;

`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  height: fit-content;
  max-width: 420px;
  border-radius: 4px;
  overflow: hidden;
  gap: 1px;
  @media screen and (max-width: 1100px) {
    width: 40%;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

const FooterTitle = styled.div<{ theme: MantineTheme }>`
  display: flex;
  justify-content: center;
  padding: 0 0 10px 0;
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
  color: ${({theme}) => theme.colorScheme === "dark"
          ? theme.colors.yellow[7]
          : theme.colors.blue[7]
  };
`;


const FooterItem = styled(Link)<{ theme: MantineTheme }>`
  display: flex;
  padding: 3px 0;
  color: ${({theme}) => theme.colorScheme === "dark"
          ? theme.colors.yellow[1]
          : theme.colors.dark[7]
  };

  &:hover {
    color: ${YELLOW_500};
  }
`;

const SocialIcons = styled.div`
  display: flex;
  padding: 3px 0;
`;

const SocialMediaIcon = styled.a`
  display: flex;
  width: fit-content;
  padding: 0 5px 0 0;
  align-items: center;
`;


const Footer = () => {
    const useTheme = useMantineTheme()

    return (
        <FooterContainer theme={useTheme}>
            <Column>
                <FooterTitle theme={useTheme}>Site Haritası</FooterTitle>
                <FooterItem theme={useTheme} to="/">Anasayfa</FooterItem>
                <FooterItem theme={useTheme} to="/hakkimizda">Hakkımızda</FooterItem>
                <FooterItem theme={useTheme} to="/bize-ulasin">İletişim</FooterItem>

            </Column>
            {/*<Column>*/}
            {/*    <FooterTitle theme={useTheme}>Bilgilendirme</FooterTitle>*/}
            {/*    <FooterItem theme={useTheme} to="/privacy-policy">*/}
            {/*        Gizlilik Politikası*/}
            {/*    </FooterItem>*/}
            {/*    <FooterItem theme={useTheme} to="/cookie-policy">Çerez Politikası</FooterItem>*/}
            {/*    <FooterItem theme={useTheme} to="/terms-of-use">Kullanım Koşulları</FooterItem>*/}
            {/*    <FooterItem theme={useTheme} to="/kvkk">Kişisel Veriler</FooterItem>*/}
            {/*    <FooterItem theme={useTheme} to="/faq">Sıkça Sorulan Sorular</FooterItem>*/}
            {/*</Column>*/}

            <Column>
                <FooterTitle theme={useTheme}>Sosyal Medya</FooterTitle>
                <SocialIcons>
                    <SocialMediaIcon href="https://www.youtube.com/@sakaryaaciltpklinigi1807" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="40px" height="40px"><path fill="#FF3D00" d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"/><path fill="#FFF" d="M20 31L20 17 32 24z"/></svg>
                    </SocialMediaIcon>
                    <SocialMediaIcon href="https://www.instagram.com/sakarya.acil.tip.klinigi/" target="_blank">
                        <InstagramIcon/>
                    </SocialMediaIcon>
                </SocialIcons>
            </Column>
        </FooterContainer>
    )
}

export default Footer

